<template>
  <a-dropdown overlayClassName="cascader-menu">
    <span :class="['cascader-menu-name', selectedValue.length && 'selected']" @click="(e) => e.preventDefault()">
      {{ option.name }} <a-icon type="down" />
    </span>
    <a-menu slot="overlay">
      <a-sub-menu v-for="childOne in option.children" :key="childOne.key" :title="childOne.name">
        <template v-if="childOne.children.length > 0">
          <a-menu-item v-for="childTwo in childOne.children" :key="childTwo.key">
            <div
              class="cascader-menu-item"
              :class="{ active: selectedValue.includes(childTwo.value) }"
              @click.stop="onClick(childOne, childTwo)"
            >
              {{ childTwo.name }}
            </div>
          </a-menu-item>
        </template>
      </a-sub-menu>
    </a-menu>
  </a-dropdown>
</template>
<script>
export default {
  name: 'CascaderMenu',
  data() {
    return {
      selectedValue: [],
    };
  },
  props: {
    defaultValue: {
      type: Array,
    },
    option: {
      type: Object,
      default: () => ({
        name: 'Node1',
        value: '0-0',
        key: '0-0',
        children: [
          {
            name: 'Child Node1',
            value: '0-0-1',
            key: '0-0-1',
            children: [
              {
                name: 'Child Node1 sub1',
                value: '0-0-1-1',
                key: '0-0-1-1',
              },
              {
                name: 'Child Node1 sub2',
                value: '0-0-1-2',
                key: '0-0-1-2',
              },
            ],
          },
          {
            name: 'Child Node2',
            value: '0-0-2',
            key: '0-0-2',
            children: [
              {
                name: 'Child Node2 sub1',
                value: '0-0-2-1',
                key: '0-0-2-1',
              },
              {
                name: 'Child Node2 sub2',
                value: '0-0-2-2',
                key: '0-0-2-2',
              },
            ],
          },
        ],
      }),
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    defaultValue: {
      handler(newValue) {
        if (newValue) {
          this.selectedValue = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClick(parent, self) {
      const findIndex = this.selectedValue.indexOf(self.value);
      let isAdd = 1;
      if (findIndex > -1) {
        this.selectedValue.splice(findIndex, 1);
        isAdd = 0;
      } else {
        if (!this.multiple) this.selectedValue = []; // 单选
        this.selectedValue.push(self.value);
      }
      const changeData = {
        selectedValue: this.selectedValue,
        parent,
        self,
        isAdd, // 1-增加 2-删除
      };
      this.$emit('change', changeData);
    },
    clearAll() {
      this.selectedValue = [];
    },
    delSelect(id) {
      const findIndex = this.selectedValue.indexOf(id);
      this.selectedValue.splice(findIndex, 1);
    },
  },
};
</script>
<style scoped lang="scss">
.cascader-menu-name {
  cursor: pointer;
  height: 27px;
  line-height: 27px;
  margin-right: 10px;
  &.selected {
    color: #1890ff;
  }
}
</style>
<style lang="scss">
.cascader-menu {
  .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
    margin-right: 0;
  }
  .ant-dropdown-menu-item {
    padding: 0;
  }
  .cascader-menu-item {
    padding: 5px 12px;
  }
  .active {
    color: #1890ff;
  }
}
</style>
