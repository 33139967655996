<template>
  <div>
    <a-modal
      dialogClass="preview-modal-dialog"
      :class="!showFooter ? 'no-footer' : ''"
      :title="title"
      :visible="visible"
      :zIndex="1001"
      :width="width"
      @cancel="handlePreviewCancel"
    >
      <div v-if="is_video">
        <video :src="previewSrc" preload="auto" controls style="height: 480px; width: 100%">
          你的浏览器不支持video
        </video>
      </div>
      <div v-else style="text-align: center">
        <img class="preview" :src="previewSrc" :style="`max-height: ${maxHeight}`"/>
      </div>
      <slot name="content"></slot>
      <template #footer>
        <template v-if="showFooter">
          <a-button key="cancel" @click="handlePreviewCancel">取消</a-button>
          <a-button key="ok" type="primary" @click="handleOk">确定</a-button>
        </template>
      </template>
    </a-modal>
  </div>
</template>

<script>
import utils from '@/common/util.js';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    previewSrc: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '480px',
    },
    maxHeight: {
      type: String,
      default: undefined,
    }
  },
  watch: {
    previewSrc: {
      handler: function (res) {
        if (res) {
          this.is_video = utils.fileSuffixTypeUtil(res, this.video_suffix_list);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      is_video: false,
      video_suffix_list: ['mp4', 'mov', 'm4v', 'flv'],
    };
  },
  methods: {
    handlePreviewCancel() {
      this.$emit('cancel');
    },
    handleOk() {
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss">
.no-footer {
  .preview-modal-dialog {
    .ant-modal-footer {
      padding: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.preview {
  max-width: 100%;
}
</style>
