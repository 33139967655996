<template>
  <!-- 图片库弹窗 -->
  <a-modal
    :visible="picStoreVisible"
    title="从图片库添加"
    :dialog-style="{ top: '15px' }"
    width="1002px"
    @cancel="handleStoreCancel"
  >
    <template slot="footer">
      <a-button @click="handleStoreCancel">取消</a-button>
      <a-button type="primary" :disabled="isSubmit" :loading="isSubmit" @click="handleStoreOk">确认</a-button>
    </template>
    <!-- 搜索栏 -->
    <a-row type="flex" style="margin-bottom: 15px">
      <a-col :span="6" style="margin-right: 15px">
        <a-tree-select
          show-search
          style="width: 100%"
          :value="picSearchForm.tagList"
          :tree-data="tagTree"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :replaceFields="{ title: 'name' }"
          placeholder="请选择标签"
          allow-clear
          multiple
          tree-default-expand-all
          :maxTagCount="2"
          @change="onTagChange"
          :filterTreeNode="filterTreeNode"
        >
        </a-tree-select>
      </a-col>
      <a-col :span="6" style="margin-right: 15px">
        <a-select
          style="width: 100%"
          mode="multiple"
          v-model="picSearchForm.principalIds"
          placeholder="请选择品牌"
          show-search
          option-filter-prop="children"
          @change="handlePrincipalChange"
          :maxTagCount="2"
          allowClear
        >
          <a-select-option v-for="item in brandList" :key="`${item.id},${item.principalName}`">{{
            item.principalName
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="6">
        <a-select
          style="width: 100%"
          mode="multiple"
          v-model="picSearchForm.vehicleSeriesIds"
          placeholder="请选择车系"
          show-search
          :filter-option="false"
          :maxTagCount="2"
          :loading="typeLoading"
          allowClear
          @search="getTypeList"
          @change="handleSearchChange"
        >
          <a-spin v-if="typeLoading" slot="notFoundContent" size="small" />
          <a-select-option v-else v-for="item in typeList" :key="`${item.id},${item.name}`">{{
            item.name
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-popover title="筛选" trigger="click">
        <template slot="content">
          <div style="width: 300px">
            <a-select
              style="width: 100%"
              v-model="picSearchForm.creator"
              placeholder="请选择添加人"
              show-search
              option-filter-prop="children"
              @change="handleSearchChange"
              allowClear
            >
              <a-select-option v-for="item in creatorList" :key="item.username" :value="item.username">{{
                item.name
              }}</a-select-option>
            </a-select>
            <a-select
              placeholder="是否使用"
              v-model="picSearchForm.editorCount"
              clearable
              style="width: 100%; margin: 10px 0"
              @change="handleSearchChange"
              allowClear
            >
              <a-select-option key="0"> 未使用 </a-select-option>
              <a-select-option key="1"> 已使用 </a-select-option>
            </a-select>
            <a-input
              placeholder="文件名"
              style="margin-bottom: 10px"
              v-model="picSearchForm.fileName"
              @keyup.enter="handleSearchChange"
              allowClear
            ></a-input>
          </div>
          <div style="text-align: end">
            <a-button @click="handleClear">清空</a-button>
          </div>
        </template>
        <div style="width: 30px; height: 30px; text-align: center; line-height: 30px; font-size: 16px; cursor: pointer">
          <a-icon type="filter" />
        </div>
      </a-popover>
    </a-row>

    <a-spin :spinning="spinning" size="large" tip="加载中...">
      <div class="container-box">
        <div class="item" v-for="(val, ind) in dataList" :key="ind">
          <a-tag class="used-tag" color="green" v-show="val.cmsFile.editorCount > 0"> 已使用 </a-tag>
          <a-checkbox-group v-model="chooseList" class="check-box">
            <a-checkbox :value="val.cmsFile.fileUrl"></a-checkbox>
          </a-checkbox-group>
          <!-- <a-checkbox class="check-box" @change="onCheckChange(val)" :checked="(chooseList[0] || {}).fileId === val.fileId"></a-checkbox> -->
          <div
            class="img-top"
            :style="{ 'background-image': `url(${val.cmsFile.coverUrl})` }"
            @click="openBigImg(val.cmsFile.fileUrl, ind)"
          ></div>
          <div class="img-bttom">
            <div class="img-title" :title="val.fileName.split('.')[0]">{{ val.fileName.split('.')[0] }}</div>
            <div class="img-tags">
              <div class="tag-txt">
                {{ arrayToString(val.attrValue.tagInfoList, 'attrName') }}
              </div>
              <a-popover title="" v-if="val.attrValue.tagInfoList">
                <template slot="content">
                  <p>{{ arrayToString(val.attrValue.tagInfoList, 'attrName') }}</p>
                </template>
                <div class="show-more" style="cursor: pointer">更多</div>
              </a-popover>
              <div class="show-more" v-else></div>
            </div>
            <div style="color: #c0c0c0">{{ val.ctime }}</div>
          </div>
        </div>
      </div>
      <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
        :options="['15', '30', '50']"
      />
    </a-spin>
    <!--查看图片-->
    <preview-modal :visible="preview_visible" :preview-src="preview_src" width="50%" @cancel="handlePreviewCancel" />
  </a-modal>
</template>

<script>
import previewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import api from '@/api/xhsAgencyApi';
import debounce from 'lodash/debounce';
import { api as viewerApi } from 'v-viewer';

export default {
  components: { previewModal },
  props: {
    picStoreVisible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    picStoreVisible: {
      handler(data) {
        if (data) {
          this.init();
          this.chooseList = [];
        }
      },
    },
  },
  data() {
    this.getTypeList = debounce(this.getTypeList, 500);
    return {
      picSearchForm: {
        tagList: [],
        creator: undefined,
        deal: undefined,
        editorCount: undefined,
        principalIds: undefined,
        vehicleSeriesIds: undefined,
      },
      spinning: false,
      typeLoading: false,
      tagList: [],
      dataList: [],
      brandList: [],
      typeList: [],
      preview_visible: false,
      preview_src: '',
      pagination: {
        current: 1,
        pageSize: 15,
        total: 0,
      },
      chooseList: [],
      creatorList: [],
      isSubmit: false,
      tagTree: [],
    };
  },
  methods: {
    init() {
      this.getTagList();
      this.getCreatorList();
      this.getDataList();
      this.getBrandList();
    },
    async getCreatorList() {
      this.handleRequest('getPictureCreatorList', (data) => {
        this.creatorList = data;
      });
    },
    async getDataList() {
      // 标签搜索
      const tagIds =
        this.picSearchForm.tagList.length === 0 ? '' : this.arrayToString(this.picSearchForm.tagList, null, ',');
      const params = {
        isDir: 0,
        refCount: 1,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        tagIds,
        deal: null,
        creator: this.picSearchForm.creator || undefined,
        editorCount: this.picSearchForm.editorCount || undefined,
        fileName: this.picSearchForm.fileName,
        principalIds: this.picSearchForm.principalIds ? this.picSearchForm.principalIds.join(',') : undefined,
        vehicleSeriesIds: this.picSearchForm.vehicleSeriesIds
          ? this.picSearchForm.vehicleSeriesIds.join(',')
          : undefined,
      };
      !tagIds && delete params.tagIds;

      this.spinning = true;
      await this.handleRequest(
        'getPicList',
        (data) => {
          this.chooseList = [];
          this.dataList = data.list.map((val) => {
            val.cmsFile.coverUrl = `${val.cmsFile.fileUrl}?x-oss-process=image/resize,l_288,m_lfit`;
            return val;
          });
          this.pagination.total = data.total;
        },
        params
      ).finally(() => {
        this.spinning = false;
      });
    },
    handleClear() {
      this.picSearchForm.creator = undefined;
      this.picSearchForm.editorCount = undefined;
      this.picSearchForm.fileName = undefined;
      this.getDataList();
    },
    handleSearchChange() {
      this.pagination.current = 1;
      this.getDataList();
    },
    initTagValue(data) {
      if (data.children && data.children.length > 0) {
        data.children = data.children.map(this.initTagValue);
      }
      return {
        ...data,
        name: data.tagName,
        value: data.id,
        key: data.id,
        disabled: data.children.length > 0 ? true : false,
      };
    },
    async getTagList() {
      await this.handleRequest('getTagTree', (data) => {
        this.tagTree = data.map(this.initTagValue);
        this.tagTree.push({
          name: '无标签',
          value: '1236389243540656128',
          key: '1236389243540656128',
          disabled: false,
        });
      });
    },
    async getBrandList() {
      await this.handleRequest('getBrandList', (data) => {
        this.brandList = data;
      });
    },
    handlePrincipalChange() {
      this.getTypeList();
      this.handleSearchChange();
    },
    getTypeList(filter) {
      filter = filter ? filter.trim() : '';
      // 是否存在品牌
      const params = {};
      if (this.picSearchForm.principalIds && this.picSearchForm.principalIds.length) {
        const principalIds =
          this.picSearchForm.principalIds.reduce((prev, item) => {
            prev += `,${item}`;
            return prev;
          }) || '';
        params.principalIds = principalIds;
        // 是否筛选车系
        if (filter) {
          // 筛选
          params.filter = filter;
        }
      } else {
        // 不选择品牌，搜索全部车系
        this.typeList = [];
        // 是否筛选车系
        if (filter) {
          // 筛选
          params.filter = filter;
        } else {
          // 不筛选车系
          this.typeList = [];
          return;
        }
      }
      this.typeLoading = true;
      this.typeList = [];
      this.handleRequest(
        'getTypeList',
        (data) => {
          this.typeList = data;
        },
        params
      ).finally(() => (this.typeLoading = false));
    },
    handleStoreCancel() {
      this.isSubmit = false
      this.$emit('closeStoreModal');
    },
    handleStoreOk() {
      if (!this.chooseList.length) return this.$message.info('请选择图片');
      this.imageDto();
    },
    async imageDto() {
      this.isSubmit = true;
      await this.handleRequest(
        'imageDto',
        (data) => {
          if (data.width > 768 || data.height > 1024 || data.size / 1024 > 3) {
            let imgUrl = this.chooseList[0];
            if (imgUrl.indexOf('x-oss-process') === -1) {
              imgUrl = `${imgUrl}?x-oss-process=image`;
            }
            const fileWidth = data.orientation === "6" || data.orientation === "8" ? data.height : data.width;
            const fileHeight = data.orientation === "6" || data.orientation === "8" ? data.width : data.height;
            // 横图过大
            if (fileWidth > fileHeight && fileWidth > 1024) {
              imgUrl += `/resize,w_${1024}`;
              const height = Math.round(fileHeight / (fileWidth / 1024));
              this.$emit('previewPressPic', { imgUrl, width: 1024, height });
              return;
            }
            // 竖图过大
            if (fileWidth < fileHeight && fileHeight > 1024) {
              imgUrl += `/resize,h_${1024}`;
              const width = Math.round(fileWidth / (fileHeight / 1024));
              this.$emit('previewPressPic', { imgUrl, height: 1024, width });
              return;
            }
            // 体积过大
            switch (data.size) {
              case data.size > 8 * 1024:
                imgUrl += '/quality,q_50';
                break;
              case data.size > 5 * 1024:
                imgUrl += '/quality,q_80';
                break;
              case data.size > 3 * 1024:
                imgUrl += '/quality,q_90';
                break;
              default:
                break;
            }
            this.$emit('addPic', imgUrl);
            this.handleStoreCancel();
          } else {
            this.$emit('addPic', this.chooseList[0]);
            this.handleStoreCancel();
          }
        },
        {
          imageUrl: this.chooseList[0],
        }
      ).finally(() => {
        this.isSubmit = false;
      });
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    // 打开大图
    openBigImg(url, index) {
      const $viewer = this.$viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
          viewed(e) {},
        },
        images: this.dataList.map((val) => val.cmsFile.fileUrl),
      });
      // if (!url) {
      //   return this.$message.error('URL为空，不支持查看！');
      // }
      // this.width = '480px';
      // this.preview_visible = true;
      // this.preview_src = url;
    },
    handlePreviewCancel() {
      this.preview_src = '';
      this.preview_visible = false;
    },
    arrayToString(val, textName, symbol = '、') {
      let str = '';
      if (val?.length) {
        let strArr = [];
        if (textName) {
          val.forEach((value) => {
            strArr.push(value[textName]);
          });
        } else {
          strArr = val;
        }
        str = strArr.reduce((prev, item) => {
          prev += `${symbol}${item}`;
          return prev;
        });
      } else {
        str = '';
      }
      return str;
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },

    onTagChange(value, selectList, node) {
      // 空选择
      if (!value.length) {
        this.picSearchForm.tagList = [];
        this.getDataList();
        return;
      }
      // 无标签
      if (node.triggerNode.value === '1236389243540656128' && node.selected) {
        this.picSearchForm.tagList = ['1236389243540656128'];
        this.getDataList();
        return;
      }
      this.picSearchForm.tagList = value;
      const index = this.picSearchForm.tagList.indexOf('1236389243540656128');
      if (index != -1) this.picSearchForm.tagList.splice(index, 1);
      this.getDataList();
    },

    filterTreeNode(inputValue, treeNode) {
      return treeNode.data.props.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
    },
  },
};
</script>

<style lang="less" scoped>
.center-line {
  display: flex;
  margin-bottom: 10px;

  .line-item {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.container-box {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height: 540px;
  overflow-y: auto;

  .item {
    position: relative;
    width: 200px;
    max-height: 300px;
    border: 1px solid #e9e9e9;
    padding-bottom: 10px;
    margin: 0 13px 20px;
    background-color: #fff;

    .check-box {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 1;

      /deep/ .ant-checkbox-inner {
        width: 25px;
        height: 25px;

        &:after {
          left: 33%;
        }
      }
    }

    .used-tag {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 20;
      border-radius: 0;
      &:hover {
        opacity: 1;
      }
    }

    .img-top {
      position: relative;
      width: 100%;
      height: 180px;
      background-repeat: no-repeat;
      // background-size: 100% 100%;
      cursor: pointer;
      background-size: cover;
      background-position: top center;
    }

    .img-edit {
      &::before {
        position: absolute;
        top: 0;
        content: '';
        width: 65px;
        height: 65px;
        background-image: url('../../../../../assets/images/used_logo.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .img-bttom {
      padding: 10px 20px;

      .img-title {
        font-weight: 600;
        width: 150px;
        white-space: nowrap;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .img-tags {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 8px 0;

      .tag-txt {
        width: 125px;
        white-space: nowrap;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #a4a4a4;
      }

      .show-more {
        width: 28px;
        height: 20px;
        line-height: 20px;
        color: #1890ff;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00152984;

    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0015294c;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }
}
</style>
